/* Full Page */
html,
body {
  background-color: black;
}

.dice {
  background-image: url("/public/images/dice.jpg");
  background-size: 50vw;
  background-repeat: repeat;
}

h1 {
  font-size: 2.3vw;
  font-family: "georgia";
  text-align: center;
}

/* Section 1 */
.section1 {
  padding: 5vw;
  padding-top: 10vw;
  padding-bottom: 6vw;
  background-color: rgba(255, 255, 255, 0.5);
}

.section1-container {
  display: flex;
  width: 80vw;
  justify-content: space-evenly;
  margin: 0 auto;
}

.section1 h1 {
  text-shadow: 5px 5px 10px #545454;
  text-align: left;
  font-size: 3vw;
}

.section1-headers {
  margin-top: auto;
  margin-bottom: auto;
}

.earth {
  width: 25vw;
  height: 25vw;
}

/* Section 2 */
.section2 {
  background-image: url("/public/images/stars.jpg");
  background-size: 40vw;
  padding: 5vw;
  padding-top: 7vw;
  padding-bottom: 7vw;
}

.container2 {
  display: flex;
  justify-content: space-evenly;
}

.videos {
  display: flex;
  flex-direction: column;
}

.video {
  width: 30vw;
  height: 17vw;
}

.vid1 {
  padding-bottom: 2.5vw;
}

.words2 {
  display: flex;
  flex-direction: column; /* Display elements vertically */
  justify-content: space-between;
  background-color: rgba(237, 210, 174, 0.85);
  padding: 4vw;
  height: 25vw;
  border-radius: 5%;
  margin-top: auto;
  margin-bottom: auto;
}

.section2 span {
  font-size: 2.5vw;
  font-family: "georgia";
  font-weight: bold;
}

.body2 {
  font-size: 1.15vw;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-left: auto;
  margin-right: auto;
}

/*Section 3*/
.section3 {
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
  padding: 3vw;
}

.section3 h1 {
  text-align: center;
}

.shorts {
  display: flex;
  justify-content: space-evenly;
  padding-top: 3vw;
  padding-bottom: 3vw;
}

.short {
  width: 16.95vw;
  height: 30vw;
  border: none;
}

.section3 h1 {
  background-color: rgba(164, 163, 168, 0.75);
}

/* Section 4 */
.section4 {
  display: flex;
  background-color: #61819d;
  font-family: "georgia";
  text-align: left;
  padding: 7vw;
}

.section4 h2 {
  font-size: 1.6vw;
}

.images {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
  margin: 0 auto;
  padding-left: 6vw;
  padding-right: 1vw;
}

img {
  width: 15vw;
  height: 15vw;
  border-radius: 100%;
}
