body,
html {
  background-color: black;
  color: black;
}

.app {
  padding-top: 7vw;
  padding-bottom: 5vw;
  background-color: gray;
}

.welcome {
  width: 80vw;
  margin: auto;
}

.engineers {
  display: flex;
  justify-content: space-between;
  padding: 4vw;
  /* background-color: blue; */
}

button {
  font-size: 1vw; /* Increase font size */
  padding: 0.5vw 1vw; /* Adjust as needed */
  margin: 0.5vw;
  border-radius: 10px;
  cursor: pointer;
}
