body,
html {
  background-color: black;
  color: black;
}

.app {
  padding-top: 7vw;
  background-color: gray;
}

.score {
  width: 80vw;
  margin-left: auto;
  margin-right: auto;
}
