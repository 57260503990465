body,
html {
  background-color: black;
  color: black;
}

.app {
  padding-top: 7vw;
  background-color: gray;
}

h1 {
  text-align: left;
}

.question-container {
  width: 70vw;
  margin: auto;
}

.answers-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.answers {
  text-align: left; /* Text inside should be left-aligned */
  font-size: 2.5vw;
  padding-left: 4vw;
}

.buttons {
  text-align: left;
  padding-top: 3vw;
  padding-bottom: 5vw;
}

.buttons button {
  font-size: 1vw; /* Increase font size */
  padding: 0.5vw 1vw; /* Adjust as needed */
  margin: 0.5vw;
  border-radius: 10px;
  cursor: pointer;
}
