.sticky-header {
  width: 100vw;
  height: 4.5vw;
  background-color: black;
  position: fixed;
  top: 0;
  z-index: 1000;
  color: white;
}

.header-container {
  width: 90vw;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.logo img {
  width: 4vw;
  height: auto;
}

.logo span {
  font-size: 2.2vw;
  font-family: "Courier New";
}

.get-started-btn {
  font-weight: bold;
  font-family: "Varela Round";
  font-size: 1vw;
  border: none;
  background-color: lightgray;
  padding: 0.5vw 1vw;
  padding-bottom: 0.6vw;
  border-radius: 10px;
  cursor: pointer;
}
